/* eslint-disable */
import React from 'react'
import styled from 'styled-components/macro'
import Article1 from './articles/1'
import Article2 from './articles/2'

const Wrapper = styled.section`
  padding: 0 20px;
  box-sizing: border-box;
  word-break: break-all;

  h4.title {
    font-size: 30px;
    font-weight: 400;
    margin: 0 0 30px 0;
  }

  a {
    color: ${({ theme }) => theme.primary1};
    word-break: break-all;
  }

  b {
    font-weight: 500;
  }

  img {
    display: block;
    max-width: 80%;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    margin: 10px auto;
  }
`

export default function Article({ articleId }: { articleId: string }) {
  const ArticleContent =  articleId == '1' ? <Article1 /> : <Article2 /> 

  return (
    <Wrapper>
      {ArticleContent}
    </Wrapper>
  )
}
