/* eslint-disable */
import React from 'react'

export default function Article() {
  return (
    <>
      <h4 className="title">Gogoswap is online</h4>

      <p>Dear Users:</p>

      <p>1. We are glad to announce that the decentralized exchange (DEX) version of Bgogo is online now. The new decentralized exchange website is https://gogoswap.finance. Gogoswap will have the same trading functionality but much better user experience. Gogoswap is safer, faster and easier to use.</p>
        
      <p>2. We will have a three-month transition period, and Bgogo will completely evolve from a centralized exchange to a decentralized exchange to capture the trend of decentralization in this industry. Bgogo will co-exist with Gogoswap for next three months to complete the transition period.</p>
        
      <p>3. All users can become familiar with the trading operations of the decentralized exchange Gogoswap as soon as possible during the transition period. <a href="#/announcements/2">(Please refer to link for specific operation guide)</a></p>
        
      <p>4. The old centralized exchange Bgogo.com will stop trading before August 31, 2021. All users shall withdraw their assets from centralized exchange to their only wallets, and connect to Gogoswap to use and feel the revolutionary changes brought by the decentralized exchange.</p>
        
      <p>5. In addition to being allocated to liquidity providers, transaction fees of Gogoswap will be used to repurchase BGG as before.</p>
        
      <p>Thank you for your support.</p>
    </>
  )
}
