import React from 'react'
import styled from 'styled-components/macro'
import { NavLink } from 'react-router-dom'

const Wrapper = styled.section`
  width: 200px;
  flex-shrink: 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    border-bottom: 1px solid ${({ theme }) => theme.bg3};
    margin-bottom: 25px;
  `};
`

const Item = styled.div<{ active?: boolean }>`
  margin-top: 10px;
  a {
    color: ${({ theme, active }) => (active ? theme.text1 : theme.text2)}};
    background: ${({ theme, active }) => (active ? theme.bg2 : 'none')};
  }
  :hover a {
    color: ${({ theme }) => theme.text1};
  }
`

const StyleLink = styled(NavLink)`
  display: block;
  text-decoration: none;
  padding: 10px;
  line-height: 22px;
  cursor: pointer;
`

export default function SideMenu({ active }: { active: string }) {
  const list = ['Gogoswap is online', 'Gogoswap user guide']

  return (
    <Wrapper>
      {list.map((item, key) => (
        <Item active={active == (key + 1).toString()} key={key}>
          <StyleLink to={'/announcements/' + (key + 1)}>{item}</StyleLink>
        </Item>
      ))}
    </Wrapper>
  )
}
