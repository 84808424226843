import React from 'react'
import SideMenu from './sideMenu'
import styled from 'styled-components/macro'
import Article from './article'
import { RouteComponentProps } from 'react-router-dom'

const Wrapper = styled.section`
  margin: 0 10%;
  position: relative;
  z-index: 2;
  padding: 30px 0px;
  display: flex;
  background: ${({ theme }) => theme.bg0};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 10px 0px 20px 0;
    display: block;
    margin: 0;
  `};
`

export default function Announcements({
  match: {
    params: { articleId: articleIdFromUrl },
  },
}: RouteComponentProps<{ articleId: string }>) {
  return (
    <Wrapper>
      <SideMenu active={articleIdFromUrl || '1'} />
      <Article articleId={articleIdFromUrl || '1'} />
    </Wrapper>
  )
}
