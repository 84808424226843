/* eslint-disable */
import React from 'react'
import png1 from './2/1.png'
import png2 from './2/2.png'
import png3 from './2/3.png'
import png4 from './2/4.png'
import png5 from './2/5.png'
import png6 from './2/6.png'
import png7 from './2/7.png'

export default function Article() {
  return (
    <>
      <h4 className="title">Gogoswap user guide</h4>

      <p>Dear Users,</p>

      <p>The blockchain industry has entered the era of defi.</p>

      <p>We have seen great potential born from Uniswap. A DEX, a decentralized exchange, attracts many users and provides an unparalleled trading experience and transparency. We are pleased to see that the blockchain industry is shifting from centralized exchange to decentralized exchange.</p>

      <p><b>This is an irreversible trend.</b></p>

      <p><b>Here is a detailed instruction about how to trade BGG/USDT on Gogoswap.</b></p>

      <p>1. Install MetaMask wallet on your desktop</p>
      <p>Use chrome：<a href="https://chrome.google.com/webstore/category/extensions">https://chrome.google.com/webstore/category/extensions</a></p>

      <p>Search MetaMask, click and install</p>

      <img src={png1} alt="" />

      <p>2. Use MetaMask as a wallet</p>

      <p>Accept the terms, create your passwords and record the 12 key words carefully.</p>

      <p>Then, you will have a wallet address from MetaMask.</p>

      <p>3. Connect MetaMask wallet to Gogoswap</p>

      <p>Before trade on Gogoswap, make sure you have deposit BGG/USDT and ETH (as Gas fee) in your wallet. </p>

      <p>Please visit： <a href="https://gogoswap.finance/#/swap">https://gogoswap.finance/#/swap</a></p>

      <img src={png2} alt="" />

      <p>Connect to a wallet button to connect to your MetaMask wallet.</p>

      <img src={png3} alt="" />

      <p>Click MetaMask and pick the address and connect. </p>

      <img src={png4} alt="" />

      <p>4. Swap BGG/USDT on Gogoswap</p>

      <img src={png5} alt="" />

      <p>You can select BGG and USDT. </p>

      <p>From BGG To USDT means you are selling BGG into USDT.</p>

      <img src={png6} alt="" />
      <img src={png7} alt="" />
      
      <p>After you confirm swap, the sell transaction will be done. </p>
    </>
  )
}
